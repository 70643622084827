<template>
  <div>
    <template v-if="loading && !$store.getters.error">
      <preloader></preloader>
    </template>
    <template v-else>
      <backButton />

      <v-form ref="form" lazy-validation>
        <v-card outlined class="mb-4">
          <v-card-title>
            {{ $t("Module name") }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              :label="$t('Name')"
              v-model="website.Name"
              required
              :rules="[
                (v) => !!v || $t('Name is required'),
                (v) =>
                  v.length >= 2 || $t('Minimum required characters is') + ' 2',
              ]"
              hide-details="auto"
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card outlined class="mb-4">
          <v-card-title>
            {{ $t("Domain names") }}
          </v-card-title>
          <v-card-text>
            <v-layout
              row
              v-for="(domain, index) in website.Domains"
              :key="index"
            >
              <v-text-field
                label="Url"
                v-model="website.Domains[index]"
                class="textInputWidth mb-2"
                required
                :rules="[
                  (v) => !!v || $t('URL is required'),
                  (v) =>
                    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/.test(
                      v
                    ) ||
                    $t(
                      'Url is not valid. The required format is http://domain.com'
                    ),
                ]"
                hide-details="auto"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
              <v-btn
                depressed
                icon
                color="primaryred"
                v-on:click="removeDomain(index)"
                class="ml-1"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-layout>
            <v-btn
              depressed
              color="primarygreen"
              class="ml-0 mt-2 edit-page-action-button"
              dark
              v-on:click="addDomain()"
            >
              {{ $t("Add domain name") }}
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card outlined class="mb-4">
          <v-card-title>
            {{ $t("Security") }}
          </v-card-title>
          <v-card-text>
            <v-switch
              flat
              :ripple="false"
              v-model="website.EnableApi"
              light
              v-bind:label="$t('Enable API')"
              hide-details="auto"
            ></v-switch>
          </v-card-text>
        </v-card>

        <v-card outlined class="mb-4">
          <v-card-title>
            {{ $t("Home page") }}
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="website.DashboardPageId['$oid']"
              item-text="name"
              item-value="id"
              :items="getHomePageNames"
              clearable
              :no-data-text="$t('No data available')"
              hide-details="auto"
              outlined
              dense
            >
            </v-select>
          </v-card-text>
        </v-card>

        <v-card outlined>
          <v-card-title>
            {{ $t("Pages") }}
          </v-card-title>
          <v-card-text>
            <template v-for="(page, index) in website.Pages">
              <pageNameLanguageSelector
                :page="page"
                :websiteId="website._id"
                @removePage="removePage(index)"
                @addWebsiteCopy="addWebsiteCopy"
                :key="index"
              />
            </template>

            <v-btn
              depressed
              color="primarygreen"
              class="ml-0 mt-2 edit-page-action-button"
              dark
              v-on:click="addPage()"
            >
              {{ $t("Add page") }}
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>

        <cancelConfirmButtons
          @goBack="goBack"
          :confirmFunction="saveWebsite"
          :loading="saving"
          :confirmLabel="$t('Save')"
        />
      </v-form>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      website: {
        Name: "",
        Domains: [""],
        DashboardPageId: { $oid: 0 },
        EnableApi: false,
        Pages: [
          {
            Name: this.$helpers.createEmptyValueBasedOnType("text"),
            RepeatingContent: false,
            Enabled: false,
            CacheContent: false,
            _id: { $oid: 0 },
            Title: this.$helpers.createEmptyValueBasedOnType("text"),
            Url: this.$helpers.createEmptyValueBasedOnType("text"),
            UniqueName: "",
            SubPages: [],
          },
        ],
      },
      headers: [
        {
          text: this.$i18n.translate("Name"),
          value: "Name",
          align: "left",
          sortable: false,
        },
        {
          text: this.$i18n.translate("Enabled"),
          value: "Enabled",
          align: "left",
          sortable: false,
        },
        {
          text: this.$i18n.translate("Repeat content"),
          value: "RepeatingContent",
          align: "left",
          sortable: false,
        },
        { text: "", value: "", align: "left", sortable: false },
      ],
      loading: false,
      saving: false,
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getHomePageNames() {
      let pages = [];

      this.website.Pages.forEach((item) => {
        if (item._id.$oid !== 0) {
          pages.push({
            id: item._id["$oid"],
            name:
              item.Name[this.currentLanguage] !== ""
                ? item.Name[this.currentLanguage]
                : item.Name["Nl"],
          });
        }
      });
      return pages;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getWebsite(this.$route.params.id);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getWebsite(id) {
      if (id) {
        this.loading = true;

        this.$request.get("/website/" + id, null, (res) => {
          this.loading = false;

          if (res.success) {
            res.data.Pages = res.data.Pages.sort(
              this.$helpers.sortByKey(this.currentLanguage)
            );
            this.website = res.data;
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },
    saveWebsite() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        const websiteToSave = JSON.parse(JSON.stringify(this.website));

        if (
          !websiteToSave["DashboardPageId"]["$oid"] ||
          websiteToSave["DashboardPageId"]["$oid"] == 0
        ) {
          delete websiteToSave["DashboardPageId"];
        }
        for (let i = 0; i < websiteToSave.Pages.length; i++) {
          if (websiteToSave.Pages[i]["_id"]["$oid"] == 0) {
            delete websiteToSave.Pages[i]._id;
          }
        }

        this.$request.put("/website", websiteToSave, (res) => {
          this.saving = false;

          if (res.success) {
            this.$router.go(-1);
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      } else {
        const toaster = {
          message: this.$i18n.translate(
            "Please make sure all fields have a correct value"
          ),
          timeout: false,
          type: "ERROR",
          toastClassName: ["toaster-color-error", "toaster-layout"],
          bodyClassName: ["toaster-text-layout"],
          icon: "mdi-alert-octagon",
        };
        this.$store.dispatch("setToaster", toaster);
      }
    },
    addDomain() {
      this.website.Domains.push("");
    },
    removeDomain(index) {
      this.website.Domains.splice(index, 1);
    },
    addWebsiteCopy(event) {
      this.$request.post("/copy-page/" + event, null, (res) => {
        if (res.data && this.$route.params.id) {
          this.getWebsite(this.$route.params.id);
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    addPage() {
      this.website.Pages.push({
        Name: this.$helpers.createEmptyValueBasedOnType("text"),
        RepeatingContent: false,
        Enabled: false,
        CacheContent: false,
        WebsiteId: this.website._id,
        _id: { $oid: 0 },
        Title: this.$helpers.createEmptyValueBasedOnType("text"),
        Url: this.$helpers.createEmptyValueBasedOnType("text"),
        UniqueName: "",
        SubPages: [],
      });
    },
    removePage(index) {
      if (
        this.website.Pages[index]._id.$oid ==
        this.website["DashboardPageId"]["$oid"]
      ) {
        this.$set(this.website["DashboardPageId"], "$oid", 0);
      }
      this.website.Pages.splice(index, 1);
    },
  },
};
</script>
